<template>
    <a href="javascript:void(0);"
        data-bs-toggle="popover"
        title="Verfügbare Sonderangebote"
        data-bs-trigger="click"
        data-bs-placement="top"
        data-bs-html="true"
        :data-bs-content="getOfferText"
        tabindex="0">
        <img class="offer-img"
            src="https://r.v-office.com/preview/v1381/1707476242000/img/offers.svg"
            width="40"
            height="40"
            aria-label="Angebote anzeigen">
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    import {
    	getVoucher
    }
    from 'voUtils/VoucherStore.js';

    export default {
    	voVueComponent: 'offer-help',
    	props: {
    		offers: Array
    	},
    	mounted: function() {


    		const title = this.voMsg('search.offers.available');
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-bs-toggle="popover"]').popover().on('shown.bs.popover', function(e) {

    			// get the dom element that the popover points to
    			var el = $(e.target);
    			// get the popover dom obj
    			var po = $('#' + el.attr('aria-describedby'));
    			// get the title
    			var poh = po.find('.popover-header');
    			poh.html(title + '<span class="popover-close ms-3" style="cursor:pointer;">&times;</span>');

    			// get the close button that we just added
    			var cb = poh.find('.popover-close');

    			// get the bootstrap popover obj
    			var bpo = bootstrap.Popover.getInstance(e.target);

    			cb.on('click', function(e) {
    				bpo.hide();
    			});

    		});
    		$('body').on('click', function(e) {
    			$('[data-bs-toggle=popover]').each(function() {
    				if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
    					(($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false;
    				}
    			});
    		});

    	},
    	methods: {
    		getVoucherCode: function() {
    			var voucher = getVoucher();
    			if (voucher) {
    				return voucher.code;
    			}
    			return null;
    		},

    	},
    	computed: {

    		getTitle: function() {
    			return '<div>' + this.voMsg('search.offers.available') + '<span class="close-popover" @click="togglePopover()">X</span></div>';
    		},

    		getOfferText: function() {
    			var offerText = '';
    			var cur = VOFFICE.currency || '&euro;';
    			//console.log('this.offers::', this.offers);
    			if (this.offers) {
    				this.offers.sort((a, b) => (a.fromdate > b.fromdate) ? 1 : -1);
    				var i;
    				offerText = "";
    				for (i = 0; i < this.offers.length; i++) {
    					var offer = this.offers[i];
    					if (!offer.voucherCode || offer.voucherCode === this.getVoucherCode()) {
    						var offer = this.offers[i];

    						offerText += '<li><a href="./s#?offerid=' + offer.id + '">';

    						offerText += offer.name + " ";
    						if (offer.discountType === 'PERCENT') {
    							offerText += "- " + offer.value / 100 + "% " + this.voMsg('tpl.text.rabatt') + " ";
    						}

    						if (offer.discountType === 'FLAT') {
    							offerText += "- " + offer.value / 100 + " " + cur + " " + this.voMsg('tpl.text.rabatt') + " ";
    						}

    						if (offer.type === 'EARLYBIRD') {
    							if (offer.timeSpan === 1) {
    								offerText += this.voMsg('tpl.offer.earlybird.arrival') + " ";
    							} else if (offer.timeSpan > 1) {
    								offerText += this.voMsg('tpl.offer.earlybird.arrival.pl') + " " + offer.timeSpan + " " + this.voMsg('tpl.offer.earlybird.days') + " ";
    							}

    						}

    						if (offer.type === 'LASTMINUTE') {
    							if (offer.timeSpan == 1) {
    								offerText += this.voMsg('tpl.search.offer.lastminute.arrival') + " ";
    							} else if (offer.timeSpan > 1) {
    								offerText += this.voMsg('tpl.search.offer.lastminute.arrival.pl') + " " + offer.timeSpan + " " + this.voMsg('tpl.text.days') + " ";
    							}

    						}


    						if (offer.fromdate) {
    							offerText += this.voMsg('tpl.text.from') + " " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " ";
    						}
    						if (offer.tilldate) {
    							offerText += this.voMsg('tpl.text.until') + " " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang) + " ";
    						}

    						if (offer.minStay > 1) {
    							offerText += "(" + this.voMsg('tpl.min') + " " + offer.minStay + " " + this.voMsg('tpl.text.nights') + ")";
    						}

    						offerText += "</a></li>";
    					}
    				}
    			}

    			return '<ul>' + offerText + '</ul>';
    		}
    	}
    };
</script>