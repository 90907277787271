<template>
    <voffice-popup-box :trigger-id="triggerId"
        class="full-xxs"
        :apply-label="applyLabel"
        @closed="onClosed">
        <table class="guestbox"
            aria-live="polite">
            <thead class="sr-only">
                <tr>
                    <th scope="col">{{ voMsg('tpl.label') }}</th>
                    <th scope="col">{{ voMsg('tpl.decrement') }}</th>
                    <th scope="col">{{ voMsg('tpl.increment') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(l, what) in sections"
                    :key="what"
                    class="gb-row">
                    <td class="gb-td label">
                        <label>{{ getFieldLabel(what) }}</label>

                        <div class="age"
                            v-if="what === 'babys'">{{ voMsg('tpl.baby.age') }}</div>
                    </td>
                    <td class="gb-td">
                        <button class="btn btn-default"
                            :aria-label="labelMinus()"
                            :disabled="!canDecr(what)"
                            @click="inc(what, -1)"
                            type="button">
                            <i class="fa-sharp fa-light fa-circle-minus icon"></i>
                            <span class="sr-only">{{ labelMinus(what) }}</span>
                        </button>
                    </td>
                    <td class="gb-td">
                        <button class="btn btn-default"
                            :aria-label="labelPlus()"
                            :disabled="!canInc(what)"
                            @click="inc(what, 1)"
                            type="button">
                            <i class="fa-sharp fa-light fa-circle-plus icon"></i>
                            <span class="sr-only">{{ labelPlus(what) }}</span>
                        </button>
                    </td>
                </tr>
                <tr v-if="maxbel">
                    <td colspan="3"
                        class="max-bel">
                        <div class="alert alert-danger"
                            role="alert">
                            {{ voMsg('tpl.text.maxOccupancyReached') }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </voffice-popup-box>
</template>

<script>
    import vofficePopupBox from './PopupBox.vue';

    export default {
    	voVueComponent: 'voffice-guestbox',
    	components: {
    		vofficePopupBox
    	},
    	voMsgCodes: [
    		'pet.pl',
    		'adult.pl',
    		'child.pl',
    		'baby.pl'
    	],
    	props: {
    		maxValues: Object,
    		minValues: Object,
    		searchData: Object,
    		showDogCountInSearch: Boolean,
    		triggerId: String,
    		applyLabel: String,
    		childrenWelcome: Boolean,
    		petsWelcome: Boolean,
    		beds: [Number, String],
    		bedsForKids: [Number, String]
    	},
    	data() {
    		return {
    			maxbel: false
    		};
    	},
    	computed: {
    		sections() {
    			var res = {
    				'adults': 'adult'
    			};
    			if (VOFFICE.settings.childrenAges && this.childrenWelcome) {
    				Object.assign(res, {
    					'children': 'child',
    					'babys': 'baby'
    				});
    			} else if (this.childrenWelcome) {
    				Object.assign(res, {
    					'children': 'child',
    					'babys': 'baby'
    				});
    			}
    			if (this.petsWelcome) {
    				res.petsCount = 'pet';
    			}
    			return res;
    		}
    	},
    	methods: {
    		labelMinus(what) {
    				if (what === "adults") {
    					return this.voMsg('tpl.removeAdults');
    				} else if (what === "children") {
    					return this.voMsg('tpl.removeChildren');
    				} else if (what === "babys") {
    					return this.voMsg('tpl.removeBaby');
    				} else if (what === "petsCount") {
    					return this.voMsg('tpl.removePet');
    				}

    			},
    			labelPlus(what) {
    				if (what === "adults") {
    					return this.voMsg('tpl.addAdults');
    				} else if (what === "children") {
    					return this.voMsg('tpl.addChildren');
    				} else if (what === "babys") {
    					return this.voMsg('tpl.addBaby');
    				} else if (what === "petsCount") {
    					return this.voMsg('tpl.addPet');
    				}
    			},
    			onClosed() {
    				this.$emit('closed');
    			},
    			getFieldLabel(what) {
    				var amount = this.searchData[what] || 0;
    				return amount + ' ' + this.voMsgPl(this.sections[what] + '.pl', amount);
    			},
    			inc(what, step) {
    				this.searchData[what] = ((this.searchData[what] || 0) - 0) + step;
    				if (what == 'petsCount') {
    					this.searchData.pets = this.searchData[what] > 0;
    				}
    			},
    			checkPersons(what) {
    				if (parseInt(this.searchData['adults']) + parseInt(this.searchData['children']) >= this.beds) {
    					this.maxbel = true;
    					return false;
    				} else {
    					this.maxbel = false;
    					return true;
    				}
    			},
    			canInc(what) {
    				var amount = ((this.searchData[what] || 0) - 0);
    				var max = this.maxValues && this.maxValues[what];
    				if (max != undefined && amount >= max) {
    					return false;
    				}
    				if (VOFFICE.settings.check4MaxBeds) {
    					if (what === 'adults' || what === 'children') {
    						if (this.beds && parseInt(this.beds) > 0) {
    							return this.checkPersons(what);
    						}
    					}
    				}
    				return true;
    			},
    			canDecr(what) {
    				var amount = ((this.searchData[what] || 0) - 0);
    				var min = this.minValues && this.minValues[what];
    				if (min == undefined) {
    					min = what == 'adults' ? 1 : 0
    				}
    				if (min != undefined && amount <= min) {
    					return false;
    				}
    				return true;
    			}
    	}
    };
</script>

<style scoped>
    .guestbox {
    	margin: 0 auto;
    	line-height: 46px;
    	text-align: left;
    	font-size: 15px;
    	color: #676767;
    }

    .gb-td {
    	padding: 8px 12px;
    }

    .gb-td.label {
    	line-height: 1;
    }

    .age {
    	font-size: 14px;
    	padding-top: 5px;
    }

    .alert-danger {
    	line-height: 20px;
    }

    .icon {
    	position: unset !important;
    	font-size: 40px !important;
    	opacity: 1 !important;
    }

    .btn {
    	width: 48px;
    	height: 48px;
    	line-height: 1;
    	border: none;
    	font-size: 40px;
    	padding: 4px;
    	color: #a7a4a4;
    }

    .btn:hover {
    	background-color: transparent;
    	color: var(--color-primary);
    }

    .btn:focus {
    	background-color: transparent;
    	color: var(--color-primary);
    }

    .btn.disabled,
    .btn:disabled {
    	color: #bbb9b9;
    }

    .label {
    	white-space: nowrap;
    }

    @media (max-width: 768px) {}
</style>